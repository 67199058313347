import React from 'react';
import logo from '../../images/logo.png'
import {Link} from 'react-router-dom'
import discord from '../../images/discord.png'
import twitter from '../../images/twitter.png'
import instagram from '../../images/instagram.png'
import './Footer.scss'

export default function Footer() {
  return <div className='footer-wrapper'>
      <div className='footer-container'>
        <div>
          <p>© copyright Sugar Proxies 2022</p>
          <div>
              <a href="https://discord.gg/X9jCUH93nW"><img src={discord} alt="" /></a>
              <a href="https://twitter.com/sugarproxies"><img src={twitter} alt="" /></a>
              <a href="https://www.instagram.com/sugarproxies/"><img src={instagram} alt="" /></a>
          </div>
        </div>
        <img src={logo} alt='' />
        <div>
            <Link to="" >Sitemap</Link>
            <Link to="/termsOfServices" >Terms of Services</Link>
            <Link to="" >Privacy Policy</Link>
        </div>
      </div>
  </div>;
}
