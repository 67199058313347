import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Success.scss'

export default function Success() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return <div className='success-wrapper' id="success">
      <div className='success-container'>
        <h2>Our Success</h2>
        <p>Work your way up-to the Sugar Factory level by using our products, <br /> posting success and getting rewarded for doing so!</p>
        <iframe data-aos="zoom-out" src="https://e.widgetbot.io/channels/934172280043495434/935978682659532830" title=" "></iframe>
        <h2>Hard Work and Dedication Pays Off!</h2>
      </div>
  </div>;
}
