import React from 'react';
import SugarCard from './SugarCard/SugarCard';
import './SugarISP.scss'

export default function SugarISP() {
  return <div className='sugar-wrapper'>
      <div className='sugar-container'>
        <h2>Sugar ISP's</h2>
        <div>
        {[
                {
                    title: "Monthly",
                    location: "Location: Ashburn, VA ",
                    provider: "Provider: Centurylink, ATT,",
                    line3: "Comcast, Sprint, NTT. ",
                    available: "Available for 30 days",
                    card: 1
                },
                {
                    title: "Weekly",
                    location: "Location: Ashburn, VA  ",
                    provider: "Provider: CenturyLink, ATT, ",
                    line3: "Comcast, Sprint, NTT. ",
                    available: "Available for 01 week",
                    card: 2
                },
                {
                    title: "Daily",
                    location: "Location: Ashburn, VA  ",
                    provider: "Provider: CenturyLink, ATT, ",
                    line3: "Comcast, Sprint, NTT. ",
                    available: "Available for 24 Hrs",
                    card: 3
                }
            ].map((item, i) => (
                <SugarCard key={i} data={item}/>
            ))}
            
        </div>
        <p>Pay attention to our announcements for restocks on our SUGARY ISP's. These <br />are our Monthly exclusive FRESH, Untouched 100% Virgin ISP's which are only <br /> available 1x per month, unless announced as back in stock!</p>
      </div>
  </div>;
}
