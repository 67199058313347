import React, {useState, useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './FAQ.scss'

export default function FAQ() {
    useEffect(() => {
        AOS.init();
      }, []);

    const [faq1Clicked, setFaq1Clicked] = useState(false);
    const [faq2Clicked, setFaq2Clicked] = useState(false);
    const [faq3Clicked, setFaq3Clicked] = useState(false);
    const [faq4Clicked, setFaq4Clicked] = useState(false);

    useEffect(() => {
        if(faq1Clicked === true){
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq1Clicked]);

      useEffect(() => {
        if(faq2Clicked === true){
            setFaq1Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq2Clicked]);

      useEffect(() => {
        if(faq3Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq3Clicked]);

      useEffect(() => {
        if(faq4Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq3Clicked(false)
        }
      }, [faq4Clicked]);

  return <div className='faq-wrapper' id="FAQs">
      <div className='faq-container'>
        <div className='emptydiv'></div>
        <div className='faqdiv'>
            <h2>FAQ's</h2>
            <div onClick={() => setFaq1Clicked(!faq1Clicked)} data-aos="fade-up" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>Why Choose Sugar Proxies?</h6>
                    {faq1Clicked === true ? <p>Rather than cutting corners we spare no expense when it comes to quality. This is why we strategically decided to utilize a series of different providers in our efforts to ensure that we are delivering a product like no other. <br/> <br />Sugar Proxies provides exceptionally fast, rarely ever throttled, unbanned, Premium ISP and Residential Proxies to guarantee our users success. Our support team is here to help and answer all of your questions!</p> : ""}
                </div>
                <div className='selector-container'><p>{faq1Clicked === false ? '+' : '-'}</p></div>
            </div>
            <div onClick={() => setFaq2Clicked(!faq2Clicked)} data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>When Will My Proxies Be Delivered?</h6>
                    {faq2Clicked === true ?<p>Proxies are delivered instantly into your dashboard.</p> :""}
                </div>
                <div className='selector-container'><p>{faq2Clicked === false ? '+' : '-'}</p></div>
            </div>
            <div onClick={() => setFaq3Clicked(!faq3Clicked)}  data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>What Sites Do We Support?</h6>
                    {faq3Clicked === true ?<p>Our Proxies are guaranteed to work on Foot sites, Supreme, Nike, Adidas, Shopify, and more.</p> : ""}
                </div>
                <div className='selector-container'><p>{faq3Clicked === false ? '+' : '-'}</p></div>
            </div>
            <div onClick={() => setFaq4Clicked(!faq4Clicked)} data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>What Kind of Authentication Will My Proxies Be?</h6>
                    {faq4Clicked === true ?<p>Our proxies are user/password authenticated</p> : ""}
                </div>
                <div className='selector-container'><p>{faq4Clicked === false ? '+' : '-'}</p></div>
            </div>
        </div>
      </div>
  </div>;
}
