import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './FeatureCard.scss'

export default function FeatureCard(props) {
    const { data } = props;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return <div data-aos="flip-up" className={data.card === 3 ? 'featureCard3-wrapper' : 'featureCard-wrapper' }>
        <img src={data.image} alt="" />
        <div>
            <h2>{data.line1}</h2>
            <h2>{data.line2}</h2>
        </div>
  </div>;
}
