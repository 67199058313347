import React from 'react';
import bannerImg from '../../images/bannerImg.png'
import './Home.scss'

export default function Home() {
  return <div className='home-wrapper' id="home">
      <div className='home-container'>
         <div>
             <h1>Join Us &amp; Experience <br /> The Sweet Taste <br /> Of Victory</h1>
             <img src={bannerImg} alt="" />
         </div>
      </div>
  </div>;
}
