import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import './ResidentialCard.scss'

export default function ResidentialCard(props) {
    const {data} = props;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return <div data-aos="flip-right" className='residentialCard-wrapper'>
      <h2>{data.header}</h2>
      <h4>{data.subHeader}</h4>
      <p>{data.p1}</p>
      <hr />
      <p>{data.p2}</p>
      <hr />
      <p>{data.p3}</p>
      <hr />
      <p>{data.p4}</p>
      <hr />
      <p>{data.p5}</p>
      <hr />
      <p>{data.p6}</p>
      <Link to={{pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Buy Now</Link>
  </div>;
}
