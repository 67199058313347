import React from 'react';
import './Windows.scss'
import WindowsCard from './WindowsCard/WindowsCard';

export default function Windows() {
  return <div className='windows-wrapper'>
      <div className='windows-container'>
         <h2>Windows 10/ <br />ISP Servers</h2>
         <div>
             {[
                 {
                     subHeader: "CANDY",
                     price: "125.00",
                     p1: "8GB RAM",
                     p2: "12 core",
                     p3: "75 Hard Disk ",
                     p4: "10Gbps Uplink",
                     p5: "Unmetered Bandwidth",
                     p6: "ISP NTT Communication",
                     p7: "Rate Limit ( MB/s )500 ",
                     p8: "Windows 10",
                 },
                 {
                    subHeader: "SWEET",
                    price: "135.00",
                    p1: "10GB RAM",
                    p2: "12 core ",
                    p3: "75 Hard Disk",
                    p4: "10Gbps Uplink ",
                    p5: "Unmetered Bandwidth",
                    p6: "ISP NTT Communication",
                    p7: "Rate Limit ( MB/s )500 ",
                    p8: "Windows 10",
                },
                {
                    subHeader: "SUGAR COATED",
                    price: "155.00",
                    p1: "12GB RAM ",
                    p2: "12 core",
                    p3: "100 Hard Disk",
                    p4: "10Gbps Uplink ",
                    p5: "Unmetered Bandwidth",
                    p6: "ISP NTT Communication ",
                    p7: "Rate Limit ( MB/s )1000 ",
                    p8: "Windows 10 "
                },
                {
                    subHeader: "SUGARY",
                    price: "175.00",
                    p1: "12GB RAM  ",
                    p2: "26 cores ",
                    p3: "200 Hard Disc",
                    p4: "10Gbps Uplink",
                    p5: "Unmetered Bandwidth ",
                    p6: "ISP NTT Communications",
                    p7: "Rate Limit ( MB/s )1000",
                    p8: "Windows 10 "
                },
                {
                    subHeader: "SUGARY PLUS",
                    price: "225.00",
                    p1: "32GB RAM ",
                    p2: "36 cores ",
                    p3: "200 Hard Disc ",
                    p4: "10Gbps Uplink",
                    p5: "Unmetered Bandwidth",
                    p6: "ISP NTT Communications",
                    p7: "Rate Limit ( MB/s )2000",
                    p8: "Windows 10 "
                  
                }
             ].map((item, i) => (
                <WindowsCard key={i} data={item}/>
             ))}
         </div>
      </div>
      </div>;
}
