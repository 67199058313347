import React from 'react';
import FeatureCard from './FeatureCard/FeatureCard';
import service1 from '../../images/service1.png'
import service2 from '../../images/service2.png'
import service3 from '../../images/service3.png'
import './Features.scss'

export default function Features() {
  return <div className='feature-wrapper' id="features">
      <div className='feature-container'>
        <h2>Factory Features</h2>
        <div>
            {[
                {
                    image:service1,
                    line1: "Never",
                    line2: "Throttled",
                    card: 1
                },
                {
                    image:service2,
                    line1: "Unbanned",
                    line2: "",
                    card: 2
                },
                {
                    image:service3,
                    line1: "Exceptionally",
                    line2: "Fast",
                    card: 3
                }
            ].map((item, i) => (
                <FeatureCard key={i} data={item}/>
            ))}
            
        </div>
      </div>
  </div>;
}
