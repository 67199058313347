import React, {useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './MobileNavbar.scss';
import logo from '../../images/logo.png'
import { HashLink } from 'react-router-hash-link';
import burger from '../../images/burger.svg'
import { Link } from 'react-router-dom';

function MobileNavbar() {
    const [isVisible, setIsVisible] = useState();
    const [nav, setNav] = useState(false);

    const toggle = () => setIsVisible(!isVisible);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
          setNav(true);
        } else {
          setNav(false);
        }
      };
      
    const largeScreens = () => {
        if (window.innerWidth > 991) {
            setIsVisible(false);
        } 
      };
      useEffect(() => {
        window.addEventListener('resize', largeScreens);
      }, []);

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
          window.removeEventListener('scroll', changeBackground);
        };
      }, []);

    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <div data-aos="fade-up" className={nav ? 'mobileNavbar-wrapper active':'mobileNavbar-wrapper'}>
            <div className='mobileNavbar-container'>
                <div className='top'>
                    <Link to="/"><img src={logo} alt="logo" /></Link>  
                    <img src={burger} alt="" onClick={toggle} /> 
                </div>
                
                {isVisible && (
                <div  data-aos="fade-down" className='bottom' onClick={toggle}>
                    <HashLink smooth to="/#home">Home</HashLink>
                    <HashLink smooth to="/#features">Features</HashLink>
                    <HashLink smooth to="/#products">Products</HashLink>
                    <HashLink smooth to="/#success">Success</HashLink>
                    <HashLink smooth to="/#FAQs">FAQ's</HashLink>
                    <Link to={ {pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Servers</Link>                   
                    <Link to={ {pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Dashboard</Link>
                </div>
                )}
            </div>
        </div>
    )
}

export default MobileNavbar
