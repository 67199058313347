import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import elipse from '../../../../../images/elipse.png'
import { Link } from 'react-router-dom';
import './SugarCard.scss'

export default function SugarCard(props) {
    const { data } = props;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return <div data-aos="flip-down" className={data.card === 1 ? 'sugarCard-wrapper1' : data.card === 2 ? 'sugarCard-wrapper2' : 'sugarCard-wrapper3' }>
        <div>
            <h5>{data.title}</h5>
            <p>{data.location}</p>
            <p>{data.provider}</p>
            <p>{data.line3}</p>
            <h6>{data.available}</h6>
            <div><Link to={{pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Buy Now</Link><img src={elipse} alt=""/></div>
            
        </div>
  </div>;
}
