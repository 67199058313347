import React from 'react';
import Footer from '../../components/Footer/Footer';
import FAQ from '../FAQ/FAQ';
import Features from '../Features/Features';
import Home from '../Home/Home';
import Products from '../Products/Products';
import Success from '../Success/Success';

export default function Main() {
  return <div>
      <Home />
      <Features />
      <Products />
      <Success />
      <FAQ />
      <Footer />
  </div>;
}
