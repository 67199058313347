import React from 'react';
import Residential from './components/Residential/Residential';
import SugarISP from './components/SugarISP/SugarISP';
import Windows from './components/Windows/Windows';

export default function Products() {
  return <div id="products">
      <SugarISP />
      <Residential />
      <Windows />
  </div>;
}
