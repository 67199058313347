import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import Navbar from './components/Navbar/Navbar'
import Main from './pages/Main/Main';
import TOS from './pages/TOS/TOS';

function App() {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "e63da3e5-d9db-4443-a494-e79aa5116441";
    (() => {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("body")[0].appendChild(s);
    })();
  });
  return (
    <div className="App">
      <Router>
        <Navbar />
        <MobileNavbar />
        <Switch>
          <Route exact path="/termsOfServices" component={TOS}/>
          <Route path="/" component={Main} />
        </Switch>       
      </Router>
    </div>
  );
}

export default App;
