import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import tick from "../../../../../images/tick.png";
import './WindowsCard.scss'

export default function WindowsCard(props) {
    const {data} = props;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return <div data-aos="flip-left" className='windowsCard-wrapper'>
      <h4>{data.subHeader}</h4>
      <h5>WINDOWS 10 / ISP SERVERS</h5>
      <h4>${data.price}/Month</h4>
      <div>
        <div> <img src={tick} alt="" /><p>{data.p1}</p></div>
        <div> <img src={tick} alt="" /><p>{data.p2}</p></div>
        <div> <img src={tick} alt="" /><p>{data.p3}</p></div>
        <div> <img src={tick} alt="" /><p>{data.p4}</p></div>
        <div> <img src={tick} alt="" /><p>{data.p5}</p></div>
        {data.p6 && ( <div> <img src={tick} alt="" /><p>{data.p6}</p></div>) }
        {data.p7 && ( <div> <img src={tick} alt="" /><p>{data.p7}</p></div>) }
        {data.p8 && ( <div> <img src={tick} alt="" /><p>{data.p8}</p></div>) }
      </div>
      <Link to={{pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Buy Now</Link>
  </div>;
}
