import React from 'react';
import './Residential.scss'
import ResidentialCard from './ResidentialCard/ResidentialCard';

export default function Residential() {
  return <div className='residential-wrapper'>
      <div className='residential-container'>
         <h2>Flavored Residential Packs</h2>
         <p>Residential Proxies</p>
         <div>
             {[
                 {
                     header: "Basic",
                     subHeader: "Candy Residential",
                     p1: "IP pools with 7 Million residential IPs",
                     p2: "Unbanned on all sites and extremely fast on Footsites with millions of IPs in our private pool!",
                     p3: "Rotating and Sticky options supporting All regions/countries",
                     p4: "Instant delivery",
                     p5: "Private network and Never throttled EVER",
                     p6: "All plans never expire!"
                 },
                 {
                    header: "Premium",
                    subHeader: "Sweet Residential",
                    p1: "Private pools with 70 Million residential IPs",
                    p2: "Unbanned on all sites and extremely fast on Footsites with millions of IPs in our private pool!",
                    p3: "Rotating and Sticky options supporting All regions/countries",
                    p4: "Instant delivery",
                    p5: "Private network and Never throttled EVER",
                    p6: "All plans never expire!"
                },
                {
                    header: "Elite",
                    subHeader: "Sugary Residential",
                    p1: "IP pools with 100 Million residential IPs",
                    p2: "Unbanned on all sites and extremely fast on Footsites with millions of IPs in our private pool!",
                    p3: "Rotating and Sticky options supporting All regions/countries",
                    p4: "Instant delivery",
                    p5: "Private network and Never throttled EVER",
                    p6: "All plans never expire!"
                }
             ].map((item, i) => (
                <ResidentialCard key={i} data={item}/>
             ))}
         </div>
      </div>
      </div>;
}
