import React, {useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Navbar.scss';
import logo from '../../images/logo.png';
import elipse from '../../images/elipse.png'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Navbar() {
    const [nav, setNav] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
          setNav(true);
        } else {
          setNav(false);
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
          window.removeEventListener('scroll', changeBackground);
        };
      }, []);

    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom"className={nav ? 'navbar-wrapper active' : 'navbar-wrapper'}>
            <div className='navbar-container'>
                <Link to="/"><img src={logo} alt="logo" /></Link>   
                <div data-aos="zoom-in">
                    <HashLink smooth to="/#home">Home</HashLink>
                    <HashLink smooth to="/#features">Features</HashLink>
                    <HashLink smooth to="/#products">Products</HashLink>
                    <HashLink smooth to="/#success">Success</HashLink>
                    <HashLink smooth to="/#FAQs">FAQ's</HashLink>
                    <div><Link to={ {pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Servers</Link><img src={elipse} alt=""/></div>                   
                    <div><Link to={ {pathname: "https://dashboard.sugarproxies.io"}} target="_blank">Dashboard</Link><img src={elipse} alt=""/></div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
